import {mod} from './utils';
import {Geolocation, Overlay} from 'ol';
import {LineString} from 'ol/geom';
import {Notification} from './notification';
import {gps_off, gps_on, marker, marker_heading} from './icons';

let DELTAMEAN = 200; // the geolocation sampling period mean in ms
const MAX_GPS_ERRORS = 30;

class GPS {

  constructor(dispatcher) {
    this.dispatcher = dispatcher
    this.locationErrors = [];
    this.toggleButton = document.getElementById('geolocate_btn');
    this.toggleButton.querySelector('.icon').innerHTML = gps_off;
    // Create GeoLcation object
    this.geoLocation = new Geolocation({
      projection: 'EPSG:3857',
      trackingOptions: {
        maximumAge: 10000,
        enableHighAccuracy: true,
        timeout: 600000
      }
    });

    this.dispatcher.addEventListener('map-pointerdrag',() => {
      this.stopGeolocation();
    });

    // When stopped with panning, resume geoLocation
    this.dispatcher.addEventListener('map-moveend',() => {
      if (this.geoLocating) {
        setTimeout(() => {
            this.startGeolocation(true)
          },
          200
        );

      }
    });

    this.addMarker();

    // LineString to store the different geoLocation positions.
    // This LineString is time aware.
    // The Z dimension is actually used to store the rotation (heading).
    this.positions = new LineString([], /** @type {ol.geom.GeometryLayout} */ ('XYZM'));

    // Listen to position changes
    this.geoLocation.on('change:position', event => {
      const position = event.target.getPosition();
      const heading = event.target.getHeading() || 0;
      const speed = event.target.getSpeed() || 0;
      const time = Date.now();

      const coords = this.positions.getCoordinates();
      const len = coords.length;
      if (len >= 2) {
        DELTAMEAN = (coords[len - 1][3] - coords[0][3]) / (len - 1);
      }

      this.addPosition(position, heading, time, speed);
      this.dispatcher.dispatchEvent('map-update-heading');
    });

    this.geoLocation.on('error', (err) => {
      const n = new Notification();
      console.error('OnGeoLocationError', err);
      if (err.type === 'error' && err.code === 2) {
        const now = new Date();
        this.locationErrors.push(now);
        if(this.locationErrors.length > MAX_GPS_ERRORS) {
          const diff = this.locationErrors[this.locationErrors.length - 10].getTime();
          if(now.getTime() - diff < 5000) {
            // 10 errors in 5 seconds, abort
            n.notify('Er is iets fout gegaan, herstart de applicatie', 'error', 5000);
            this.stopGeolocation();
            return;
          }
        }
        setTimeout(() => {
          n.notify('GeoLocatie herstarten', 'info', 1000);
          this.startGeolocation(true);
        }, 100)

      } else {
        n.notify('GeoLocatie kan niet gestart worden', 'error', 5000);
        this.stopGeolocation()
      }
    });

    this.toggleButton.addEventListener('click', () => {
      if (this.geoLocating) {
        this.geoLocating = false;
        this.stopGeolocation();

      } else {
        this.startGeolocation();
        this.geoLocating = true;

      }
    }, false);
  }

  addMarker() {
    this.markerEl = document.getElementById('geolocation_marker');
    this.marker = new Overlay({
      positioning: 'center-center',
      element: this.markerEl,
      stopEvent: false
    });

    this.dispatcher.dispatchEvent('map-add-overlay', this.marker);
  }

  addPosition(position, heading, timestamp, speed) {
    const x = position[0];
    const y = position[1];
    const coordinates = this.positions.getCoordinates();
    const previous = coordinates[coordinates.length - 1];
    const prevHeading = previous && previous[2];
    if (prevHeading) {
      let headingDiff = heading - mod(prevHeading);

      // force the rotation change to be less than 180°
      if (Math.abs(headingDiff) > Math.PI) {
        const sign = (headingDiff >= 0) ? 1 : -1;
        headingDiff = -sign * (2 * Math.PI - Math.abs(headingDiff));
      }
      heading = prevHeading + headingDiff;
    }
    this.positions.appendCoordinate(/** @type ol.Coordinate */[x, y, heading, timestamp]);
    // only keep the 20 last coordinates
    this.positions.setCoordinates(this.positions.getCoordinates().slice(-20));

    // FIXME use speed instead
    if (heading && speed) {
      this.markerEl.innerHTML = marker_heading;
    } else {
      this.markerEl.innerHTML = marker;
    }
  }

  startGeolocation(force) {
    if(!this.geoLocating || force){
      this.toggleButton.querySelector('.icon').innerHTML = gps_on;
      this.geoLocation.setTracking(true); // Start position tracking
      this.toggleButton.classList.add('btn--geolocate-active');
      this.dispatcher.addEventListener('map-postcompose', this.updateView.bind(this));
      this.geoLocation.on('change', this.updateFirstView.bind(this));
    }
  }

  stopGeolocation() {
    this.toggleButton.classList.remove('btn--geolocate-active');
    this.toggleButton.querySelector('.icon').innerHTML = gps_off;
    this.geoLocation.setTracking(false);
    this.geoLocation.un('change', this.updateFirstView.bind(this));
    try {
      this.dispatcher.removeEventListener('map-postcompose', this.updateView.bind(this));

    } catch (e) {
      // Not registered yet
    }
  }

  /**
   * First render of geolocation using the change event, subsequent changes
   * are fired through the postcompose event
   */
  updateFirstView() {
    this.geoLocation.un('change', this.updateFirstView.bind(this));
    this.updateView();
  }

  updateView() {
    if(!this.geoLocating) {
      return;
    }
    // use sampling period to get a smooth transition
    let mean = Date.now() - DELTAMEAN * 1.2;
    mean = Math.max(mean, this.previousMean || 0);
    this.previousMean = mean;
    // interpolate position along positions LineString
    /**
     * @type {module:ol/coordinate~Coordinate}
     */
    const coordinate =  this.positions.getCoordinateAtM(mean, true);

    if (coordinate) {
      this.dispatcher.dispatchEvent('map-animate-view', coordinate);

      this.marker.setPosition(coordinate);
    }
  }
}

export {GPS}
