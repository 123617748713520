import 'ol/ol.css';
import {radToDeg} from './utils';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile'
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
import {TileWMS} from 'ol/source';
import OSM from 'ol/source/OSM';
import WMTS, {optionsFromCapabilities}  from 'ol/source/WMTS';
import {transform} from 'ol/proj';
import {DragPan, PinchZoom, PinchRotate, MouseWheelZoom} from 'ol/interaction';
import {add, marker_heading, remove} from './icons';
import vikfetch from './fetch';

let map, view;

const MAX_ZOOM = 24;
const MIN_ZOOM = 20;


class VikMap {

  constructor(el, dispatcher) {
    this.dispatcher = dispatcher
    this.zoom = 22;
    this.heading = document.getElementById('heading_marker');
    this.heading.innerHTML = marker_heading;
    this.activeLayer = 1;

    this._create(el);
    this._addListeners();
    this._addButtons();

  }

  _create(el) {
    if (!map) {
      this._createLayers();

      const CENTER = transform([5.522026, 53.068024], 'EPSG:4326', 'EPSG:3857');
      view = new View({
        center: CENTER,
        zoom: this.zoom,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM
      });

      // creating the map
      map = new Map({
        layers: this.layers,
        loadTilesWhileInteracting: true,
        loadTilesWhileAnimating: true,
        target: el,
        interactions: [
          new DragPan(),
          new PinchZoom(),
          new PinchRotate(),
          new MouseWheelZoom()
        ],
        controls: [
        ],
        view: this.getView()
      });

    }
  }

  _addListeners() {
    map.on('moveend', event => {
      if (this.zoom !== event.map.getView().getZoom()) {
        this.zoom = event.map.getView().getZoom();
      }
      this.dispatcher.dispatchEvent('map-moveend', event);
    });

    map.on('pointerdrag', event => {
      this.dispatcher.dispatchEvent('map-pointerdrag', event);
      this.dispatcher.dispatchEvent('map-update-heading');
    });
    map.on('postcompose', event => {
      this.dispatcher.dispatchEvent('map-postcompose', event);
    });
    map.on('singleclick',  event => {
      this.dispatcher.dispatchEvent('map-single-click', event);
    });

    this.dispatcher.addEventListener('map-update-heading', this.updateHeading.bind(this));
    this.dispatcher.addEventListener('map-animate-view', (event) => {
      view.animate({
        rotation:-event.data[2],
        // center: coordinate,
        center: this._getCenterWithHeading(event.data, -event.data[2], view.getResolution()),
        duration: 25
      });
    });
    this.dispatcher.addEventListener('map-add-layer', (event) => {
      map.addLayer(event.data);
    });
    this.dispatcher.addEventListener('map-add-overlay', (event) => {
      map.addOverlay(event.data);
    })
  }

  _addButtons() {

    const layerRadios = document.querySelectorAll('.input__control--radio');
    for(let i = 0; i < layerRadios.length; i++) {
      layerRadios.item(i).addEventListener('change', e => {
        this.layers[this.activeLayer].setVisible(false);
        this.activeLayer = e.currentTarget.value * 1;
        this.layers[this.activeLayer].setVisible(true);
      })
    }

    this.zoomInBtn = document.getElementById('zoom_in_btn');
    this.zoomOutBtn = document.getElementById('zoom_out_btn');
    this.zoomInBtn.querySelector('.icon').innerHTML = add;
    this.zoomOutBtn.querySelector('.icon').innerHTML = remove;
    this.zoomInBtn.addEventListener('click', () => this.setZoom(1));
    this.zoomOutBtn.addEventListener('click', () => this.setZoom(-1));
  }

  _createLayers() {

    // Create the aerial layer, disabled by default
    const aerial = new TileLayer({
      source: new TileWMS({
        url: process.env.WMS_URL,
        params: {'LAYERS': process.env.WMS_LUFO, 'TILED': true},
        serverType: 'geoserver'
      }), title: `Luchtfoto's`, type: 'base', visible: true
    });

    // Create osm layer (default)
    const osmLayer = new TileLayer({
      source: new OSM(
      ), title: 'OSM', visible: false, type: 'base'
    });
    this.layers = [
      osmLayer,
      aerial
    ];

    this._getCapabilities()
  }

  _getCapabilities() {
    const parser = new WMTSCapabilities();
    vikfetch({
      method: 'get',
      url:`${process.env.WMS_BRT}?request=GetCapabilities`,
    }).then(text => {
      // Fetch the capabilities from the WMTS Server and create
      // a new layer with the correct settings
      const result = parser.read(text);
      const options = optionsFromCapabilities(result, {
        layer: 'brtachtergrondkaartpastel',
        matrixSet: 'EPSG:3857',
      });

      const brt = new TileLayer({
        source: new WMTS(options),
        title: `BRT`, type: 'base', visible: false
      });
      const layers = map.getLayers();
      // Set the z-index so it will be below the vergunningen layer
      layers.insertAt(2, brt)
      this.layers.push(brt)
    });

  }
  /**
   * recenters the view by putting the given coordinates
   * at 3/4 from the top or the screen
   * @param position
   * @param rotation
   * @param resolution
   * @returns {*[]}
   */
  _getCenterWithHeading(position, rotation, resolution) {
    const size = map.getSize();
    const height = size[1];

    return [
      position[0] - Math.sin(rotation) * height * resolution * 1 / 4,
      position[1] + Math.cos(rotation) * height * resolution * 1 / 4
    ];
  }

  /**
   *
   * @returns {ol.Map}
   */
  getMap() {

    return map;
  }

  /**
   * Controls the zoom using the buttons
   * @param dir
   */
  setZoom(dir) {

    if(map) {
      this.zoom = Math.max(MIN_ZOOM, Math.min(dir + this.zoom, MAX_ZOOM));
      console.log('zooming to', this.zoom)
      map.getView().animate({
          zoom: this.zoom,
          duration: 50
        });
    }
  }

  getView() {
    return view;
  }

  /**
   * Updates the heading indicator
   */
  updateHeading() {
    this.heading.style.transform = `rotate(${radToDeg(view.getRotation())}deg)`;
  }
}

export {VikMap};
